var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rq-queues" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "filter-bar" },
        [
          _c("div", { staticClass: "green-label" }, [_vm._v("Filters")]),
          _c("search-filters", {
            ref: "searchFilters",
            attrs: { filters: _vm.filters },
            on: { change: _vm.changeFilter, "press-enter": _vm.applyFilter }
          }),
          _c(
            "div",
            [
              _c("b-button", { on: { click: _vm.applyFilter } }, [
                _vm._v("Apply")
              ]),
              _c("b-button", { on: { click: _vm.resetFilters } }, [
                _vm._v("Reset")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.workerList,
          fields: _vm.fields,
          "select-mode": "single",
          "selected-variant": "success",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
          filterIncludedFields: _vm.filterFields
        }
      }),
      _c("div", { staticClass: "green-divider" }),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "table-results" }, [
          _vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-bar" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                variant: "dark",
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "page-buttons",
              attrs: {
                variant: "dark",
                "total-rows": _vm.workerList.length,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }