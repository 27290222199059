<template>
  <div class="rq-queues">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
      <div class="green-label">Filters</div>
      <search-filters
        :filters="filters"
        @change="changeFilter"
        @press-enter="applyFilter"
        ref="searchFilters"
      ></search-filters>
      <div>
        <b-button
          @click="applyFilter">Apply</b-button>
        <b-button
          @click="resetFilters">Reset</b-button>
      </div>

    </div>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="workerList" :fields="fields" select-mode="single" selected-variant="success"
             th-class="bv-table-header" :current-page="currentPage" :per-page="perPage"
             :filter="filterMethod === 'local' ? filter : undefined"
             :filterIncludedFields="filterFields"
             class="bv-table">
    </b-table>
    <div class="green-divider"></div>
        <div class="footer">
          <div class="table-results">
            Results: {{totalRows}}
          </div>
          <div class="page-bar">
            <b-form-select
              variant="dark"
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              class="page-select">
            </b-form-select>
            <b-pagination
              variant="dark"
              v-model="currentPage"
              :total-rows="workerList.length"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="page-buttons"></b-pagination>
          </div>
        </div>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'

import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'
import * as AlertHelper from '../helpers/AlertHelper'
import SearchFilters from '@/components/shared/SearchFilters.vue'
export default {
  name: 'admin-rq-queues',
  components: {
    SearchFilters,
    LoadingBox
  },
  props: {
    products: Array
  },
  data: function () {
    return {
      loading: true,
      filterMethod: 'local', // Filter Locally or remotely at the server-side
      filter: '',
      filterFields: null,
      currentTask: null,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      fields: [
        { key: 'task_id',
          label: 'Task',
          sortable: true
        },
        { key: 'status',
          label: 'Status',
          sortable: true
        },
        { key: 'created_at',
          label: 'Created',
          sortable: true
        },
        { key: 'enqueued_at',
          label: 'Queued',
          sortable: true
        },
        { key: 'started_at',
          label: 'Started',
          sortable: true
        },
        { key: 'ended_at',
          label: 'Ended',
          sortable: true
        }
      ],
      filters: [
            {
            label: 'Filter By State',
            type: 'select',
            placeholder: 'state...',
            fieldName: 'status',
            options: [
              { value: 'queued', text: 'queued' },
              { value: 'started', text: 'started' },
              { value: 'deferred', text: 'deferred' },
              { value: 'finished', text: 'finished' },
              { value: 'stopped', text: 'stopped' },
              { value: 'scheduled', text: 'scheduled' },
              { value: 'canceled', text: 'canceled' },
              { value: 'failed', text: 'failed' }
            ]
          },
          {
            label: 'Filter By Task Id',
            type: 'string',
            placeholder: 'Filter By Task Ide',
            fieldName: 'task_id'
          }
        ],
      currentFilter: {},
      workerList: []
    }
  },
  async created () {
    if (this.tasks) {
      this.workerList = this.tasks
    } else {
      await this.refresh()
    }
    this.loading = false
  },
  methods: {
    applyFilter: function () {
      this.refresh()
    },
    changeFilter: function (filters) {
      console.log('Set Filters: ', filters)
      this.currentFilter = filters
    },
    resetFilters: function () {
      this.$refs.searchFilters.reset()
      this.refresh()
    },
    refresh: async function () {
      let resp = await DataProvider.adminGetRQJobs()
      if (resp.success) {
        let jobs = resp.data.rq_jobs
        if (resp.data.rq_jobs.length) {
          jobs = jobs.reverse()
        }
        // Apply filters locally, we might add them to the API later if needed
        if (Object.hasOwn(this.currentFilter, 'status')) {
          jobs = jobs.filter(t => t.status === this.currentFilter.status)
        }
        if (Object.hasOwn(this.currentFilter, 'task_id')) {
          this.filterFields = ['task_id']
          this.filter = this.currentFilter.task_id
        } else {
          this.filterFields = []
          this.filter = ''
        }
        this.workerList = jobs
        this.totalRows = jobs.length
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm')
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .rq-queues{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
  }

  .table-results {
    color: $theme-color-primary-4;
    font-weight: 600;
  }

  .queue-container {
    color: $theme-color-primary-3;
  }
</style>
